<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile', params: { realm: this.$route.params.realmId } }">Profile</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>

        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">My Roles and Permits</h1>
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Roles</p>
                <ul>
                    <template v-for="role in roleList">
                        <li :key="role">
                            <span v-if="roleText[role]">{{ roleText[role] }}</span>
                            <span v-if="!roleText[role]">{{ role }}</span>
                        </li>
                    </template>
                    <template v-if="roleList.length === 0">
                        <li>No special roles</li>
                    </template>
                </ul>
                <!-- <p class="mb-0 pb-0">
                    {{ JSON.stringify(permit) }}
                </p> -->
                <!-- <p class="text-overline mb-0 mt-8">Security</p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'profile-settings-authn' }">Change my login settings</router-link>
                </p> -->
                <!-- <pre>
                    {{ JSON.stringify(this.session, null, 2) }}
                </pre> -->
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';
// import { toText } from '@/sdk/time';

export default {
    data: () => ({
        roleText: {
            'service-admin': 'Service Administrator',
        },
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        permit() {
            return this.user.permit;
        },
        roleList() {
            return this.user.permit?.role ?? [];
        },
    },
    methods: {
        async getCurrentUser() {
            try {
                await this.$store.dispatch('loadUser');
                // this.$store.commit('loading', { getCurrentUser: true });
                // const response = await this.$client.authn.get();
                // console.log(`got session info: ${JSON.stringify(response)}`);
                // this.refreshAfter = response.duration;

                // console.log(`next refresh ms ${response.refreshAfter} text ${this.nextRefreshText}`);
            } catch (err) {
                console.error('failed to load user', err);
            } finally {
                // this.$store.commit('loading', { getCurrentUser: false });
            }
        },
    },
    mounted() {
        this.getCurrentUser();
    },
};
</script>
